import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { Table } from 'antd';
import { useCollapsed } from '../../contexts/CollapsedContext'; // Assuming this context exists

// Styled Table component
const StyledTable = styled(Table)`
  .ant-table-tbody {
    min-height: ${(props) => props.tableHeight}px;
  }
  
  // .ant-table-tbody .ant-table-placeholder {
  //     height: ${(props) => props.tableHeight}px !important;
  //   }

  .ant-table-tbody-virtual-holder {
    width: ${(props) => props.tableWidth}px;
  }
  .ant-table-header {
    width: ${(props) => props.tableWidth}px;
  }
`;

const Tablec = ({ columns, dataSource, customEmpty, cardsContainerRef,tabs=false,actionBar=false,pagination=false,rowKey }) => {
  const [tableHeight, setTableHeight] = useState(0);
  const [width, setWidth] = useState(0);
  const { collapsed } = useCollapsed(); // Context to know whether the sidebar is collapsed
  
  const header = 82 + 100 ;
  const tabsH = tabs ? 75 + 32 : 0 ; // 32 padding
  const actionBarH = actionBar ? 45 : 0 ;
  const paginationsH = pagination ? 32 : 0 ;

  useEffect(() => {
    const adjustTableDimensions = () => {
      const redusableHeight = header + tabsH + paginationsH + actionBarH; // Fixed height for header
      const cardsHeight = cardsContainerRef?.current?.offsetHeight || 0; // Height of the cards container
      const availableHeight = window.innerHeight - redusableHeight - cardsHeight;
      const newWidth = collapsed ? window.innerWidth - 80 : window.innerWidth - 250;
      
      setWidth(newWidth - 36); // Adjust for any padding or border if needed
      setTableHeight(availableHeight); // Set table height dynamically
    };

    // Adjust table dimensions on load
    adjustTableDimensions();

    // Adjust table dimensions when the window is resized
    window.addEventListener('resize', adjustTableDimensions);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', adjustTableDimensions);
    };
  }, [collapsed, cardsContainerRef]);

  return (
    <StyledTable
      tableHeight={tableHeight}
      tableWidth={width}
      virtual
      size="small"
      bordered
      rowKey={(record) => record[rowKey]}
      pagination={false}
      scroll={{ y: tableHeight }}
      columns={columns}
      dataSource={dataSource}
     // loading={getProjectsDetailsIssuesLoader}
     locale={{
      emptyText: customEmpty, // Apply the custom empty state
    }}
    // onRow={(record) => ({
    //   onClick: () => {
    //     navigate("/project-detail", {
    //       state: { projectId: record.id, projectName: record.name },
    //     });
    //   },
    // })}
    />
  );
};

export default Tablec;
