import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Table,Modal,
  Collapse,theme,
  Avatar,
  Skeleton,
  Input,
  Spin
} from "antd";
import noDataIcon from "../../assets/img/noDataIcon.svg";
import eolcard from "../../assets/img/eolcard.svg";
// import noDataIconSmall from "../../assets/img/noDataIconSmall.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  actionAddService,
  actionGetProjectServiceProductData,
  actionGetSingleProductData,
} from "../../store/services/projectService";
import { CaretRightOutlined,RightSquareOutlined,SearchOutlined } from '@ant-design/icons';
import CategoryComp from "../UI/Scrollcards/CategoryComp";
import Title from "antd/es/typography/Title";
import SelectedCateComp from "../UI/Scrollcards/SelectedCateComp";
import content from "./mockData"

const defaultWidth = 75;
const focusedWidth = 400;

const EndofLife = () => {
const { token } = theme.useToken();
const navigate = useNavigate();
const location = useLocation();
const dispatch = useDispatch();
const { state } = location;
const projectId = state?.projectId;
const projectName = state?.projectName;
const selectedServices = state?.selectedServices || {};
const isFromeolpage =  state?.isFromeolpage == null ? true : state?.isFromeolpage ;
const [included, setIncluded] = useState([]);
const [excluded, setExcluded] = useState([]);
const [activeCollapseKey, setactiveCollapseKey] = useState([2]);
const [tempSelectedRows, setTempSelectedRows] = useState({});
const [width, setWidth] = useState(defaultWidth);
const searchRef = React.useRef(null);
const panelStyle = {
  marginBottom: 24,
  background: token.colorFillAlter,
  borderRadius: token.borderRadiusLG,
  border: 'none',
};
  const [searchValue, setSearchValue] = useState("");
  const [selectProductOptions, setSelectProductOptions] = useState();
  const [selectedRows, setSelectedRows] = useState({});
  const [open, setOpen] = useState(false);
  const [sigleProdTemp, setsigleProdTemp] = useState();
  const [modifiedPostdatas, setmodifiedPostdatas] = useState({});


  const {
    getProjectServiceProductDataLoader,
    projectServiceProductData,
    getSingleProductDataLoader,
    singleProductData,
    addServiceLoader
  } = useSelector((state) => state.project);

  const customEmpty = (
    <div className="flex flex-col items-center">
      <img src={noDataIcon} alt="No Data" />
      <p className="text-[#393939] text-[15px] font-montserratMedium">
        No Data Found
      </p>
    </div>
  );

  // const handleSearchProduct = (value, arr) => setSearchValue(value);
  // const handleSelectProduct = (value) => { searchRef.current.value = searchValue}

  useEffect(() => {
      dispatch(actionGetProjectServiceProductData({ name: "", page: 0 }));
  }, [dispatch]);

  useEffect(() => {
    try{
      if(projectServiceProductData && projectServiceProductData?.contents?.length > 0){
        setSelectProductOptions([...projectServiceProductData?.contents]);
      }else{
        setSelectProductOptions([]);
      }
  }catch(e){
    setSelectProductOptions([]);
    console.log('Error',e)
  }
  }, [projectServiceProductData]);

  const handleChangeServiceProduct = (value) => {
    setOpen(true)
    setsigleProdTemp(value)
      const productId = value?.id;
      if (productId) {
        dispatch(actionGetSingleProductData({projectId,productId}));
      }
  };

 
  // const handleDeselectServiceProduct = (id) => {
  //   dispatch(removeProduct(id));
  //    setSelectedRows((prevSelectedRows) => {
  //   const updatedSelectedRows = { ...prevSelectedRows };
  //   delete updatedSelectedRows[id]; // Remove the selected rows for the deselected product
  //   return updatedSelectedRows;
  // });
  // };

  const productColumns = [
    {
      title: "Release Version",
      dataIndex: "releaseVersion",
      render: (text) => <span className="ml-2">{text || "-"}</span>,
    },
    {
      title: "Released On",
      dataIndex: "releaseOn",
      render: (text) => <span className="ml-2">{text || "-"}</span>,
    },
    {
      title: "Active Support",
      dataIndex: "activeSupport",
      render: (text) => <span className="ml-2">{text || "-"}</span>,
    },
    // {
    //   title: "Additional Info",
    //   dataIndex: "additionalInfo",
    //   ellipsis: true,
    //   render: (text) => <span className="ml-2">{text || "-"}</span>,
    // },
  ];

  const isAnyRowSelected = () => {
    return Object.values(selectedRows).some((selectedRowKeys) => selectedRowKeys.length > 0);
  };
 
  const cardDelete = (product) => {  

    dispatch(actionAddService({ id: projectId, values: [{
      productId: product?.id,
      selectedSupportDetailsIds: [], 
      unselectedEOLId: [], 
      isDeleted: true
    }] }))
    .then(() => {
      setSelectedRows((prevSelectedRows) => {
        const updatedSelectedRows = {...prevSelectedRows };
          delete updatedSelectedRows[product?.id]; 
        return updatedSelectedRows;
      });
      setTempSelectedRows((prevSelectedRows) => {
        const updatedSelectedRows = {...prevSelectedRows };
          delete updatedSelectedRows[product?.id]; 
        return updatedSelectedRows;
      });
      setmodifiedPostdatas(prevState => {
        const updatedState = { ...prevState };       
        delete updatedState[product?.id];
        return {
          ...updatedState,
        };
      });
    })
    .catch((error) => {
      console.error("Error deleting service:", error);
    });
  };

  const handleAddService = () => {
      const selectedData = Object.keys(selectedRows).map((productId) => ({
        productId,
        selectedSupportDetailsIds: selectedRows[productId] || [],
      }));
  
      dispatch(actionAddService({ id: projectId, values: Object.values(modifiedPostdatas) }))
      .then(() => {
        navigate(`/${projectName}/project-detail`, {
          state: {
            projectId: projectId,
            projectName: projectName,
            tab: "3",
          },
        });
      })
      .catch((error) => {
        console.error("Error adding service:", error);
      });
    };

  const handleRowSelectionChange = (product, selectedRowKeys) => {
    const tempSate = {};
    const productId = product?.productId;
    let isnothingSelected = selectedRowKeys.length === 0; 
    tempSate[productId] = selectedRowKeys;    
    setTempSelectedRows((prevSelectedRows) => {
      const updatedSelectedRows = {...prevSelectedRows,...tempSate };
      if (isnothingSelected) {
        delete updatedSelectedRows[productId]; 
      }
      return updatedSelectedRows;
    });

    if (!modifiedPostdatas[productId]) {
      setmodifiedPostdatas(prevState => ({
        ...prevState,
        [productId]: {
          productId: productId,
          selectedSupportDetailsIds: [],
          // unselectedEOLId: [] 
        }
      }));
    }

    let alreadySelectdItems = product?.supportDetails?.filter((el) => el?.selected === true) || [];

    if (isnothingSelected) {
      if (alreadySelectdItems.length > 0) {
        setmodifiedPostdatas(prevState => ({
          ...prevState,
          [productId]: {
            productId:productId,
            selectedSupportDetailsIds: [], 
            unselectedEOLId: [], 
            isDeleted: true
          }
        }));
      }else{
        // new
        setmodifiedPostdatas((prevSelectedRows) => {
          const updatedSelectedRows = {...prevSelectedRows };
            delete updatedSelectedRows[productId]; 
          return updatedSelectedRows;
        });
      }
    } else {
      // something selected
      if (alreadySelectdItems.length > 0) {
        alreadySelectdItems.forEach((el) => {
          if (!selectedRowKeys.includes(el?.productDetailsId)) {
            setmodifiedPostdatas(prevState => {
              const updatedState = { ...prevState };       
              delete updatedState[productId]?.isDeleted;
              return {
                ...updatedState,
                [productId]: {
                  ...updatedState[productId], 
                  unselectedEOLId: [
                    ...(updatedState[productId]?.unselectedEOLId || []), 
                    el?.eolId 
                  ]
                }
              };
            });
          }else{
            setmodifiedPostdatas(prevState => {
              const updatedState = { ...prevState };       
              delete updatedState[productId]?.isDeleted;

              let updatedEolids = [
                ...(updatedState[productId]?.unselectedEOLId || []),
              ];

              const indexToRemove = updatedEolids?.findIndex(eol => eol === el?.eolId);
              if (indexToRemove !== -1) updatedEolids?.splice(indexToRemove, 1);

              return {
                ...updatedState,
                [productId]: {
                  ...updatedState[productId], 
                  unselectedEOLId: updatedEolids
                }
              };
            });
          }
        });
      }
        setmodifiedPostdatas(prevState => {
          const updatedState = { ...prevState };       
          delete updatedState[productId]?.isDeleted;
        
          return {
            ...updatedState,
            [productId]: {
              ...updatedState[productId], 
              selectedSupportDetailsIds: selectedRowKeys,
            }
          };
        });
      
    }


    // setSelectedRows((prevSelectedRows) => {
    //   if (selectedRowKeys.length === 0) {
    //     const newState = { ...prevSelectedRows };
    //     delete newState[productId]; 
    //     return newState;
    //   }
    //   const updatedSelectedRows = {...prevSelectedRows,...tempSate };
    //   return updatedSelectedRows;
    // });

    // setTempSelectedRows((prev) => {
    //   console.log("@@@",prev)
    //   // if (selectedRowKeys.length === 0) {
    //   //   const newState = { ...prev };
    //   //   delete newState[productId]; 
    //   //   return newState;
    //   // }
    //   return {
    //     ...prev,
    //     ...tempSate
    //   };
    // });
  };

useEffect(() => {
  const selectedCardsarray = [];
  const allCardsarray = [];
  let filteredSelectedOptions = [];
  const selectedKeysSet = new Set(Object.keys(selectedRows));

  let searchValueWithWildcards = searchValue?.replace(/\*/g, '.*') // '*' matches zero or more characters in RegExp
    ?.replace(/\?/g, '.')  // '?' matches exactly one character in RegExp
    ?.replace(/[.*+?^=!:${}()|\[\]\/\\]/g, '\\$&'); // Escape any other special characters

  const searchRegex = new RegExp(searchValueWithWildcards, 'i');

  filteredSelectedOptions = selectProductOptions?.map(option => {
  const matchedProducts = option.products.filter(service => 
    searchRegex.test(service?.description)
  );

  if (matchedProducts.length > 0) {
    return { ...option, products: matchedProducts };
  }
  return null;
  }).filter(option => option !== null);

  for (let i = 0, len = filteredSelectedOptions?.length; i < len; i++) {
    const categoryData = filteredSelectedOptions[i];
    let tempProductsofcurCate = categoryData.products;
    selectedCardsarray.push({"category":categoryData.category,products:[]});
    allCardsarray.push({"category":categoryData.category,products:[]});

    // Loop through the products in each category
    for (let j = 0, serviceLen = tempProductsofcurCate.length; j < serviceLen; j++) {
      const product = tempProductsofcurCate[j];  
      if (selectedKeysSet.has(product.id)) {
        selectedCardsarray[i].products.push(product);
      } else {
        allCardsarray[i].products.push(product);
      }
    }
  }

  selectedCardsarray.length ? setactiveCollapseKey([1,2]) : setactiveCollapseKey([2])
  setIncluded(selectedCardsarray);
  setExcluded(allCardsarray);
  }, [selectedRows,tempSelectedRows,selectProductOptions,searchValue]);
  
useEffect(() => {
  if (selectedServices.length > 0) {
    const updatedPrepreEx = {};
    // console.log("selectedServices",selectedServices)
    selectedServices.forEach(element => {
      updatedPrepreEx[`${element?.productId}`] = [];
      setmodifiedPostdatas(prevState => {
        const updatedState = { ...prevState };
        if (updatedState[element?.productId]) {
          updatedState[element?.productId].selectedSupportDetailsIds.push(element?.productSupportDetailsId);
        } else {
          updatedState[element?.productId] = {
            productId: element?.productId,
            selectedSupportDetailsIds: [element?.productSupportDetailsId], // Push the first productSupportDetailsId
            unselectedEOLId: []
          };
        }
        return updatedState; // Return the updated state
      });
    });
    setSelectedRows(updatedPrepreEx); 
    setTempSelectedRows(updatedPrepreEx); 
  }
}, [selectedServices]);

useEffect(() => {
  const tempSate = {};
  if (singleProductData.length > 0) {
    singleProductData[0]?.supportDetails?.forEach(element => {
     if(element?.selected || selectedRows?.[singleProductData[0]?.productId]?.includes(element?.productDetailsId)){
      if (!tempSate[`${singleProductData[0]?.productId}`]) {
        tempSate[`${singleProductData[0]?.productId}`] = [];
      }
      tempSate[`${singleProductData[0]?.productId}`].push(element?.productDetailsId);
      
      // if (!modifiedPostdatas[singleProductData[0]?.productId]) {
      //   modifiedPostdatas[singleProductData[0]?.productId] = {
      //     projectId:singleProductData[0]?.productId,
      //     selectedSupportDetailsIds:[],
      //     unselectedEOLId: []
      //   };
      // }
      // if(element?.selected) modifiedPostdatas[singleProductData[0]?.productId].selectedSupportDetailsIds.push(element?.productDetailsId);

      // Assuming `modifiedPostdatas` is part of the state and `setmodifiedPostdatas` is the setter function

      // if (!modifiedPostdatas[singleProductData[0]?.productId]) {
      //   setmodifiedPostdatas(prevState => ({
      //     ...prevState,
      //     [singleProductData[0]?.productId]: {
      //       projectId: singleProductData[0]?.productId,
      //       selectedSupportDetailsIds: [],
      //       unselectedEOLId: []
      //     }
      //   }));
      // }

      // if (element?.selected) {
      //   setmodifiedPostdatas(prevState => ({
      //     ...prevState,
      //     [singleProductData[0]?.productId]: {
      //       ...prevState[singleProductData[0]?.productId], // Keep the existing properties
      //       selectedSupportDetailsIds: [
      //         ...prevState[singleProductData[0]?.productId].selectedSupportDetailsIds,
      //         element?.productDetailsId
      //       ]
      //     }
      //   }));
      // }
    }
    });
    setSelectedRows((prevSelectedRows) => {
      const updatedSelectedRows = {...prevSelectedRows,...tempSate };
      return updatedSelectedRows;
    });
    setTempSelectedRows((prevSelectedRows) => {
      const updatedSelectedRows = {...prevSelectedRows,...tempSate };
      return updatedSelectedRows;
    });
  }
}, [singleProductData]);

const handleClear = () => setSearchValue("") ;
const onSearch = (e) => {
  const inputValue = searchRef.current?.input?.value;
  setSearchValue(inputValue);
}

  useEffect(()=> {
    if(width != defaultWidth) {
      searchRef?.current?.focus({
        cursor: 'end',
      });
    }
    else searchRef?.current?.blur();
  },[width])
  
  return (
    <>
      <Spin spinning={getProjectServiceProductDataLoader}>
      <div className="w-full flex justify-end">
        {width == defaultWidth ?
        ( <Button type="primary" onClick={() => setWidth(focusedWidth)} shape="circle" icon={<SearchOutlined style={{fontSize:14}} />} /> ):(
          <Input
          placeholder={width == defaultWidth ? "" : "Search services..."}
          allowClear
          style={{ width:width }}
          suffix={
            <RightSquareOutlined 
            onClick={() => { 
              handleClear();
              setWidth(defaultWidth)
            }}
            style={{fontSize:14}}
            /> 
          }
          size="samll"
          onChange={onSearch}
          ref={searchRef}
          onClear={handleClear}
        />
        )}
        {!isFromeolpage && <Button
          // disabled={!isAnyRowSelected()} // Disable if no rows are selected
          loading={addServiceLoader}
          onClick={handleAddService}
          type="primary"
          className="ml-3 min-h-[35px] min-w-[120px] text-[14px]"
          htmlType="submit"
        >
          Save To Project
        </Button>}
        <div className="mt-3 lg:mt-5">
        <Modal
          classNames={{ body: 'eolModalView eol-scroll' }}
          title={
            !getSingleProductDataLoader ? "" : <Skeleton.Button 
            active={!getSingleProductDataLoader} 
            size={'small'} 
            style={{ width:"200px" }} /> 
          }
          centered
          open={open}
          onOk={() => {
            setSelectedRows(tempSelectedRows);
            setOpen(false)
          }}
          styles={{
            body: { transition: 'opacity 0.8s ease-in-out'}
          }} 
          loading={getSingleProductDataLoader}
          onCancel={() => setOpen(false)}
          okButtonProps={{ disabled:  isFromeolpage  }}
          width={{ xs: '90%',sm: '80%',md: '70%',lg: '80%',xl: '70%',xxl: '70%'}} >
          {singleProductData &&
              singleProductData.length > 0 &&
              singleProductData.map((product, index) => {
                const rowSelection = {
                  selectedRowKeys: tempSelectedRows[product?.productId] || [],
                  onChange: (selectedRowKeys) =>
                  handleRowSelectionChange(product, selectedRowKeys),
                  getCheckboxProps: (record) => ({
                    disabled: isFromeolpage ,
                  })
                };

              return (
                <>
                  <div key={index} className="mb-4">
                    <div className="w-[98%] h-[20px] rounded flex items-center mb-2"> 
                      <p className="ml-3 text-black font-montserratMedium text-[16px]">
                        {product?.description || product?.productName || sigleProdTemp?.description || sigleProdTemp?.productName}
                      </p>
                    </div>
                    <Card
                      style={{
                        border:"none",
                        backgroundColor : "transparent"
                      }}
                    > 
                      <Card.Meta
                        avatar={<Avatar src={product?.imgUrl || sigleProdTemp?.imageUrl || eolcard} />}
                        title=""
                        description={
                          <>
                            <p>{product?.additionalInfo || `Descriptions not found`}</p>
                          </>
                        }
                      />
                    </Card>
                    {/* Dynamic Table for Each Product */}
                    <Table
                      rowSelection={rowSelection}
                      rowKey={(record) => record?.productDetailsId}
                      pagination={false}
                      className="bg-transparent"
                      columns={productColumns}
                      dataSource={
                        Array.isArray(product?.supportDetails)
                          ? product?.supportDetails
                          : []
                      }
                      scroll={{ y: 300 }}
                      locale={{ emptyText: customEmpty }}
                    />
                  </div>
                </>
              );
            })}
      </Modal>
      </div>
      </div>
      <div className="pt-3 w-full">
        <div className="eol-height eol-scroll">
        <Collapse
            bordered={false}
            activeKey={activeCollapseKey}
            onChange={(key) => setactiveCollapseKey(key)}
            expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
            style={{
              background: "#f8f8fa",
            }}
            items={[
              {
              key: 1,
              label: (
                <>
                  <Title level={5}>Selected</Title>  
                </>
              ),
              children: <>
                <CategoryComp 
                searchValue={searchValue} 
                from="old" 
                selectProductOptions={included} 
                onCardClick={handleChangeServiceProduct}
                onCardDelete={cardDelete} />
              </>,
              style: panelStyle,
            },{
              key: 2,
              label: (
                <>
                  <Title level={5}>All</Title>  
                </>
              ), 
              children: (
              <>
              <CategoryComp searchValue={searchValue} from="new" selectProductOptions={excluded} onCardClick={handleChangeServiceProduct} />
              </>
              ),
              style: panelStyle,
            },
          ]}
          />            
        </div>
      </div>
      </Spin>
  </>
  );
};

export default EndofLife;
