export function isVersionGreater(version, latestVersion) {
    // Normalize versions by splitting into arrays of numbers/strings
    const parseVersion = (v) => v?.split(/[\.\-v]/)?.map((part) => (isNaN(part) ? part : Number(part)));
  
    const versionParts = parseVersion(version);
    const latestVersionParts = parseVersion(latestVersion);
  
    // Compare each part of the version
    for (let i = 0; i < Math.max(versionParts?.length, latestVersionParts?.length); i++) {
      const v1 = versionParts[i] || 0; // Use 0 if part is missing
      const v2 = latestVersionParts[i] || 0;
  
      if (v1 > v2) return false; // version is greater
      if (v1 < v2) return true; // latestVersion is greater
    }
  
    return false; // Versions are equal
  }
  
  export const optionsDataprepare = (array) => {
    try {
      return array
        .map((item) => ({
          ...item,
          value: item?.id,
          label: item?.description || item?.name,
          id: item.id,
        }))
        .sort((a, b) => a.label?.localeCompare(b.label)); 
    } catch (e) {
      console.error('Error preparing options data:', e.message);
      return []; 
    }
  };

  export const escapeRegExp = (str) => {
    return str?.replace(/[.*+?^=!:${}()|\[\]\/\\]/g, '\\$&');
  };
  
  export const highlightText = (text, searchValue) => {
    if (!searchValue) return text;
  
    // Create a case-insensitive regex to match the search term in the text
    const regex = new RegExp(`(${searchValue})`, 'gi');
    
    // Replace matched text with highlighted version
    return text.replace(regex, '<span class="highlight">$1</span>');
  };