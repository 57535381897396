import { createSlice } from "@reduxjs/toolkit";
import { actionGetGitOrgs,actionGetGitOrgRepo, actionSCMauth } from "../services/scmService";

const scmSlice = createSlice({
  name: "scm",
  initialState: {
    getSCMauthUrl: "",
    getgetSCMauthUrlLoader: false,
    actionGetGitOrgsData:[],
    actionGetGitOrgsLoader:false,
    actionGetGitOrgRepoData:[],
    actionGetGitOrgRepoLoader:false,
  },
  reducers: {
    setGitOrgsData: (state, action) => {
      state.actionGetGitOrgsData = action.payload; 
    },
    setGitOrgRepoData: (state, action) => {
      state.actionGetGitOrgRepoData = action.payload; 
    }
  },
  extraReducers: (buider) => {
    buider
      .addCase(actionSCMauth.pending, (state) => {
        state.getgetSCMauthUrlLoader = true;
      })
      .addCase(actionSCMauth.fulfilled, (state, action) => {
        state.getSCMauthUrl = action.payload;
        state.getgetSCMauthUrlLoader = false;
      })
      .addCase(actionSCMauth.rejected, (state) => {
        state.getgetSCMauthUrlLoader = false;
      })

      // git orgs State
      .addCase(actionGetGitOrgs.pending, (state) => {
        state.actionGetGitOrgsLoader = true;
      })
      .addCase(actionGetGitOrgs.fulfilled, (state, action) => {
        state.actionGetGitOrgsData = action.payload;
        state.actionGetGitOrgsLoader = false;
      })
      .addCase(actionGetGitOrgs.rejected, (state) => {
        state.actionGetGitOrgsLoader = false;
      })
      // git orgs repos 
      .addCase(actionGetGitOrgRepo.pending, (state) => {
        state.actionGetGitOrgRepoLoader = true;
      })
      .addCase(actionGetGitOrgRepo.fulfilled, (state, action) => {
        state.actionGetGitOrgRepoData = action.payload;
        state.actionGetGitOrgRepoLoader = false;
      })
      .addCase(actionGetGitOrgRepo.rejected, (state) => {
        state.actionGetGitOrgRepoLoader = false;
      })
  },
});

export const {
  setGitOrgsData,
  setGitOrgRepoData,
} = scmSlice.actions;


export default scmSlice.reducer;
