import React, { useEffect, useState } from "react";
import { Card, Col, Progress, Row, Spin } from "antd";
import Highcharts, { color } from "highcharts";
import HighchartsReact from "highcharts-react-official";
import axios from "axios";
import { useTheme } from "../common/ThemeContext";
import { useDispatch, useSelector } from "react-redux";
import {
  actionGetDashboardPieChartData,
  actionGetPortfolioVulnerabilitiesChartData,
  actionGetProjectsEndOfLife,
  actionGetProjectsFindings,
} from "../../store/services/projectService";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc"; // Import the UTC plugin
import timezone from "dayjs/plugin/timezone"; // Import the timezone plugin
import MyChartComponent from "./HighChartComp";

// Extend dayjs with UTC and timezone plugins
dayjs.extend(utc);
dayjs.extend(timezone);

const MainDashboard = () => {
  const { isDarkMode } = useTheme();
  const [data, setData] = useState([]);
  const { token, orgId } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const {
    projectsFindings,
    getProjectsfindingsLoader,
    projectsEndOfLife,
    getProjectsEndOfLifeLoader,
    portfolioVulnerabilitiesChartData,
    getPortfolioVulnerabilitiesChartDataLoader,
    dashboardPieChartData,
    getDashboardPieChartDataLoader,
  } = useSelector((state) => state.project);

  useEffect(() => {
    if (orgId && token) {
      dispatch(actionGetProjectsFindings(orgId));
      dispatch(actionGetProjectsEndOfLife(orgId));
      dispatch(actionGetPortfolioVulnerabilitiesChartData(orgId));
      dispatch(actionGetDashboardPieChartData(orgId));
    }
  }, [orgId, token, dispatch]);

  // Extract categories from the API response
  const categories = portfolioVulnerabilitiesChartData?.data?.categories;
  // Generate the series array for Highcharts
  const series =
    categories &&
    categories.map((category) => {
      return {
        name: category.name,
        data: category.data,
        color:
          category.name === "Critical"
            ? "#FF4544"
            : category.name === "High"
            ? "#FF8D00"
            : category.name === "Medium"
            ? "#FFC800"
            : category.name === "Low"
            ? "#48C373"
            : "#A0A0A0", // for Unassigned
        marker: {
          symbol: "circle",
        },
      };
    });

  const options = {
    chart: {
      type: "area",
      backgroundColor: isDarkMode ? "#202020" : "#fff",
    },
    title: {
      text: null,
    },
    yAxis: {
      title: {
        text: null,
      },
      labels: {
        style: {
          color: isDarkMode ? "#fff" : "#000",
        },
      },
      tickAmount: 5,
      gridLineWidth: 0,
    },
    xAxis: {
      type: "datetime",
      labels: {
        enabled: false,
      },
    },
    tooltip: {
      shared: true,
      crosshairs: true,
      backgroundColor: "#202020",
      style: {
        color: "#fff",
        fontSize: "12px",
      },
    },
    plotOptions: {
      area: {
        stacking: "normal",
        fillOpacity: 0.2,
      },
    },
    legend: {
      align: "left",
      itemStyle: {
        color: isDarkMode ? "#fff" : "#000",
      },
      itemHoverStyle: {
        color: isDarkMode ? "#fff" : "#000",
      },
    },
    credits: {
      enabled: false,
    },
    series: [
      {
        name: "Critical",
        data: [
          ["2024-08-20", 356],
          ["2024-08-21", 400],
          ["2024-08-22", 450],
          ["2024-08-23", 480],
          ["2024-08-24", 500],
          ["2024-08-25", 520],
          ["2024-08-26", 550],
        ],
        color: "#FF4544",
        marker: {
          symbol: "circle",
        },
      },
      {
        name: "High",
        data: [
          ["2024-08-20", 1161],
          ["2024-08-21", 1200],
          ["2024-08-22", 1300],
          ["2024-08-23", 1350],
          ["2024-08-24", 1400],
          ["2024-08-25", 1450],
          ["2024-08-26", 1500],
        ],
        color: "#FF8D00",
        marker: {
          symbol: "circle",
        },
      },
      {
        name: "Medium",
        data: [
          ["2024-08-20", 1649],
          ["2024-08-21", 1700],
          ["2024-08-22", 1800],
          ["2024-08-23", 1850],
          ["2024-08-24", 1900],
          ["2024-08-25", 1950],
          ["2024-08-26", 2000],
        ],
        color: "#FFC800",
        marker: {
          symbol: "circle",
        },
      },
      {
        name: "Low",
        data: [
          ["2024-08-20", 135],
          ["2024-08-21", 140],
          ["2024-08-22", 150],
          ["2024-08-23", 155],
          ["2024-08-24", 160],
          ["2024-08-25", 165],
          ["2024-08-26", 170],
        ],
        color: "#48C373",
        marker: {
          symbol: "circle",
        },
      },
      {
        name: "Unassigned",
        data: [
          ["2024-08-20", 227],
          ["2024-08-21", 230],
          ["2024-08-22", 240],
          ["2024-08-23", 245],
          ["2024-08-24", 250],
          ["2024-08-25", 255],
          ["2024-08-26", 260],
        ],
        color: "#A0A0A0",
        marker: {
          symbol: "circle",
        },
      },
    ], // Apply the dynamically generated series
  };

  const pieChartOptions = {
    chart: {
      plotBorderWidth: null,
      plotShadow: false,
      type: "pie",
      backgroundColor: isDarkMode ? "#202020" : "#fff",
      height: 380,
    },
    title: {
      text: null,
      align: "left",
    },
    tooltip: {
      pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
      backgroundColor: "#202020", // Set the background color here
      style: {
        color: "#fff", // Text color inside the tooltip
        fontSize: "12px",
      },
    },
    accessibility: {
      point: {
        valueSuffix: "%",
      },
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: {
          enabled: false,
        },
        showInLegend: true,
      },
    },
    credits: {
      enabled: false, // Disable the Highcharts credit label
    },
    legend: {
      itemStyle: {
        color: isDarkMode ? "#fff" : "#000", // Set the legend label font color to red
      },
      itemHoverStyle: {
        color: isDarkMode ? "#fff" : "#000", // Set the legend label font color to red on hover as well
      },
    },
    series: [
      {
        name: "Severity",
        colorByPoint: true,
        data: [
          {
            name: "Critical",
            y: dashboardPieChartData?.critical,
            color: "#FF4544",
          },
          {
            name: "High",
            y: dashboardPieChartData?.high,
            color: "#FF8D00",
          },
          {
            name: "Medium",
            y: dashboardPieChartData?.medium,
            color: "#FFC800",
          },
          {
            name: "Low",
            y: dashboardPieChartData?.low,
            color: "#48C373",
          },
          {
            name: "Unassigned",
            y: dashboardPieChartData?.unassigned,
            color: "#A0A0A0",
          },
        ],
      },
    ],
  };

  return (
    <>
      <Spin
        spinning={
          getProjectsfindingsLoader ||
          getProjectsEndOfLifeLoader ||
          getPortfolioVulnerabilitiesChartDataLoader ||
          getDashboardPieChartDataLoader
        }
      >
        <div>
          <Card className="bg-card-bg text-text-primary border-card-border">
            <div>
              <Row gutter={[16, 16]} justify="space-between">
                <Col xs={24} sm={24} md={24} lg={24}>
                  <p className="text-[14px] lg:text-[16px] font-montserratMedium text-text-primary">
                    Supply Chain Findings
                  </p>
                </Col>
                <Col xs={24} sm={12} md={12} lg={6}>
                  <div className="border px-6 py-3 text-text-primary bg-card-bg rounded-[10px] border-blue-border">
                    <h1 className="font-montserratMedium text-[17px]">
                      {projectsFindings ? projectsFindings.projects : "0"}
                    </h1>
                    <p className="pt-2 text-[15px]">Projects</p>
                    {/* <div className="pt-2" style={{ width: "100%" }}>
              <Progress
                percent={30}
                size="small"
                showInfo={false}
                strokeColor="#2B81F6"
                trailColor="#CCDEFA"
              />
            </div> */}
                  </div>
                </Col>

                <Col xs={24} sm={12} md={12} lg={6}>
                  <div className="border px-6 py-3 text-text-primary bg-card-bg rounded-[10px] border-red-border">
                    <h1 className="font-montserratMedium text-[17px]">
                      {projectsFindings ? projectsFindings.riskScore : "0"}
                    </h1>
                    <p className="pt-2 text-[15px]">Risk Score</p>
                    {/* <div className="pt-2" style={{ width: "100%" }}>
              <Progress
                percent={40}
                size="small"
                showInfo={false}
                strokeColor="#915EFF"
                trailColor="#E0CCFA"
              />
            </div> */}
                  </div>
                </Col>

                <Col xs={24} sm={12} md={12} lg={6}>
                  <div className="border px-6 py-3 text-text-primary bg-card-bg rounded-[10px] border-purple-border">
                    <h1 className="font-montserratMedium text-[17px]">
                      {projectsFindings
                        ? projectsFindings.vulnerableProjects
                        : "0"}
                    </h1>
                    <p className="pt-2 text-[15px]">Vulnerable Projects</p>
                    {/* <div className="pt-2" style={{ width: "100%" }}>
              <Progress
                percent={70}
                size="small"
                showInfo={false}
                strokeColor="#FF5EC8"
                trailColor="#F1CCFA"
              />
            </div> */}
                  </div>
                </Col>

                <Col xs={24} sm={12} md={12} lg={6}>
                  <div className="border px-6 py-3 text-text-primary bg-card-bg rounded-[10px] border-pink-border">
                    <h1 className="font-montserratMedium text-[17px]">
                      {projectsFindings
                        ? projectsFindings.supplyChainFindings
                        : "0"}
                    </h1>
                    <p className="pt-2 text-[15px]">Supply Chain Findings</p>
                    {/* <div className="pt-2" style={{ width: "100%" }}>
              <Progress
                percent={90}
                size="small"
                showInfo={false}
                strokeColor="#FF985E"
                trailColor="#FADACC"
              />
            </div> */}
                  </div>
                </Col>
              </Row>
            </div>
          </Card>
        </div>
        {/* bottom card */}
        <div className="mt-4">
          <Card className="bg-card-bg text-text-primary border-card-border">
            <div>
              <Row gutter={[16, 16]}>
                <Col xs={24} sm={24} md={24} lg={24}>
                  <p className="text-[14px] lg:text-[16px] font-montserratMedium text-text-primary">
                    Services - End of Life
                  </p>
                </Col>
                <Col xs={24} sm={12} md={12} lg={6}>
                  <div className="border px-6 py-3 text-text-primary bg-card-bg rounded-[10px] border-blue-border">
                    <h1 className="font-montserratMedium text-[17px]">
                      {projectsEndOfLife ? projectsEndOfLife.projects : "0"}
                    </h1>
                    <p className="pt-2 text-[15px]">Projects</p>
                    {/* <div className="pt-2" style={{ width: "100%" }}>
                    <Progress
                      percent={40}
                      size="small"
                      showInfo={false}
                      strokeColor="#2B81F6"
                      trailColor="#CCDEFA"
                    />
                  </div> */}
                  </div>
                </Col>

                <Col xs={24} sm={12} md={12} lg={6}>
                  <div className="border px-6 py-3 text-text-primary bg-card-bg rounded-[10px] border-red-border">
                    <h1 className="font-montserratMedium text-[17px]">
                      {projectsEndOfLife ? projectsEndOfLife.noOfService : "0"}
                    </h1>
                    <p className="pt-2 text-[15px]">Number of Services</p>
                    {/* <div className="pt-2" style={{ width: "100%" }}>
                    <Progress
                      percent={10}
                      size="small"
                      showInfo={false}
                      strokeColor="#F62B2B"
                      trailColor="#FACCCC"
                    />
                  </div> */}
                  </div>
                </Col>

                <Col xs={24} sm={12} md={12} lg={6}>
                  <div className="border px-6 py-3 text-text-primary bg-card-bg rounded-[10px] border-purple-border">
                    <h1 className="font-montserratMedium text-[17px]">
                      {projectsEndOfLife ? projectsEndOfLife?.eolCount : "0"}
                    </h1>
                    <p className="pt-2 text-[15px]">EOL Findings</p>
                    {/* <div className="pt-2" style={{ width: "100%" }}>
                    <Progress
                      percent={50}
                      size="small"
                      showInfo={false}
                      strokeColor="#2B81F6"
                      trailColor="#CCDEFA"
                    />
                  </div> */}
                  </div>
                </Col>
              </Row>
            </div>
          </Card>
        </div>

        {/* Chart starts here
        <div className="mt-4" style={{ minHeight: "400px" }}>
          <Card className="bg-card-bg text-text-primary border-card-border">
            <h1 className="text-text-primary font-montserratMedium text-[16px]">
              {portfolioVulnerabilitiesChartData
                ? portfolioVulnerabilitiesChartData.title
                : "-"}
            </h1>
            <p className="text-text-primary text-[12px] mt-1 mb-2">
              {portfolioVulnerabilitiesChartData
                ? portfolioVulnerabilitiesChartData.subtitle
                : "-"}
            </p>
            <HighchartsReact highcharts={Highcharts} options={options} />
            <MyChartComponent categories={categories} isDarkMode={isDarkMode} />
            <div className="flex justify-center mt-3">
              <div className="border px-[19px] py-[12px] align-middle rounded-[22px] mr-2">
                <h1>1M</h1>
              </div>
              <div className="border px-[19px] py-[12px] align-middle rounded-[22px] mr-2">
                <h1>3M</h1>
              </div>
              <div className="border px-[19px] py-[12px] align-middle rounded-[22px] mr-2">
                <h1>6M</h1>
              </div>
              <div className="border px-[19px] py-[12px] align-middle rounded-[22px] mr-2">
                <h1>1Y</h1>
              </div>
              <div className="border px-[19px] py-[12px] align-middle rounded-[22px] mr-2">
                <h1>ALL</h1>
              </div>
            </div>
          </Card>
        </div> */}

        {/* two card start here */}
        <div className="mt-4">
          <Row gutter={[8, 12]}>
            <Col xs={24} sm={24} md={24} lg={12}>
              <Card className="border-card-border bg-card-bg rounded-[10px]">
                <div className="" style={{ minHeight: "200px" }}>
                  <h1 className="text-text-primary font-montserratMedium text-[16px]">
                    Policy Violations
                  </h1>
                  <p className="text-text-primary text-[12px] mt-1 mb-2">
                    Policy Violations by State
                  </p>
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={pieChartOptions}
                  />
                </div>
              </Card>
            </Col>
            {/* <Col xs={24} sm={24} md={24} lg={12}>
              <Card className="border-card-border bg-card-bg rounded-[10px]">
                <div className="" style={{ minHeight: "200px" }}>
                  <h1 className="text-text-primary font-montserratMedium text-[16px]">
                    Policy Violations
                  </h1>
                  <p className="text-text-primary text-[12px] mt-1 mb-2">
                    Policy Violations by Classification
                  </p>
                </div>
              </Card>
            </Col> */}
          </Row>
        </div>

        {/* 2 two card start here */}
        {/* <div className="mt-4">
          <Row gutter={[8, 12]}>
            <Col xs={24} sm={24} md={24} lg={12}>
              <Card className="border-card-border bg-card-bg rounded-[10px]">
                <div className="" style={{ minHeight: "200px" }}>
                  <h1 className="text-text-primary font-montserratMedium text-[16px]">
                    Auditing Progress
                  </h1>
                  <p className="text-text-primary text-[12px] mt-1 mb-2">
                    Policy Violations by State
                  </p>
                </div>
              </Card>
            </Col>
            <Col xs={24} sm={24} md={24} lg={12}>
              <Card className="border-card-border bg-card-bg rounded-[10px]">
                <div className="" style={{ minHeight: "200px" }}>
                  <h1 className="text-text-primary font-montserratMedium text-[16px]">
                    Auditing Progress
                  </h1>
                  <p className="text-text-primary text-[12px] mt-1 mb-2">
                    Policy Violations
                  </p>
                </div>
              </Card>
            </Col>
          </Row>
        </div> */}

        {/* 3 three card */}
        {/* <div className="mt-4">
          <Row gutter={[8, 12]}>
            <Col xs={24} sm={24} md={24} lg={12}>
              <Card className="border-card-border bg-card-bg rounded-[10px] pb-2">
                <div className="" style={{ minHeight: "200px" }}>
                  <h1 className="text-text-primary font-montserratMedium text-[16px]">
                    Projects
                  </h1>
                </div>
              </Card>
            </Col>
            <Col xs={24} sm={24} md={24} lg={12}>
              <Card className="border-card-border bg-card-bg rounded-[10px]">
                <div className="" style={{ minHeight: "200px" }}>
                  <h1 className="text-text-primary font-montserratMedium text-[16px] pb-2">
                    Components
                  </h1>
                </div>
              </Card>
            </Col>
          </Row>
        </div> */}
      </Spin>
    </>
  );
};

export default MainDashboard;
