import {
  Button,
  Checkbox,
  Col,
  Collapse,
  Dropdown,
  Empty,
  Flex,
  Form,
  Input,
  Modal,
  Pagination,
  Progress,
  Row,
  Select,
  Spin,
  Tooltip,
  Upload,
  Radio,
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import searchIconGrey from "../../assets/img/searchIconGrey.svg";
import projectSearchIcon from "../../assets/img/projectSearchIcon.svg";
import projectUserIcon from "../../assets/img/projectUserIcon.svg";
import gitIconBlack from "../../assets/img/gitIconBlack.svg";
import gitIconWhite from "../../assets/img/gitIconWhite.svg";
import playIcon from "../../assets/img/playIcon.svg";
import paushIcon from "../../assets/img/paushIcon.svg";
import sbomFileIcon from "../../assets/img/sbomFileIcon.svg";
import cliIcon from "../../assets/img/cliIcon.svg";
import cicdIcon from "../../assets/img/cicdIcon.svg";
import uploadIcon from "../../assets/img/uploadIcon.svg";
import watchIcon from "../../assets/img/watchIcon.svg";
import deleteIcon from "../../assets/img/deleteIcon.svg";
import dropdownIcon from "../../assets/img/dropdownIcon.svg";
import filterIcon from "../../assets/img/filterIcon.svg";
import shortingIcon from "../../assets/img/shortingIcon.svg";
import packageIcon from "../../assets/img/packageIcon.svg";
import codeIcon from "../../assets/img/codeIcon.svg";
import noDataIcon from "../../assets/img/noDataIcon.svg";
import { useTheme } from "../common/ThemeContext";
import { useDispatch, useSelector } from "react-redux";
import { jwtDecode } from "jwt-decode";
import {
  actionAddProject,
  actionGetAddProjectmodalfiletypes,
  actionGetProjectsByOrgId,
  actionGetProjectsFindings,
  actionUpdateProject,
  action_deleteProject
} from "../../store/services/projectService";
const { Dragger } = Upload;
import { toast } from "sonner";
import { useNavigate,useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import { eolFindingTooltips, Vulnerabilities } from "./Constants";
import Tablec from "../common/Table"
import { actionGetGitFileUpload, actionGetGitOrgRepo, actionPutGitUpdateFile, actionSCMauth, actionSCMcallback } from "../../store/services/scmService";
import { setGitOrgsData } from '../../store/slices/scmSlice';
import { ClockCircleFilled, EditOutlined } from '@ant-design/icons';

const Project = () => {
  const cardsContainerRef = useRef(null);
  const editGitProject = useRef(false);
  const { isDarkMode } = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const [isGitProjectModalOpen, setIsGitProjectModalOpen] = useState(false);
  const [isGitReposModalOpen, setIsGitReposModalOpen] = useState(false);
  const [isAddProjectModalOpen, setIsAddProjectModalOpen] = useState(false);
  const [isUpdateProjectModalOpen, setIsUpdateProjectModalOpen] = useState(false);
  const [isScanOptionModalOpen, setIsScanOptionModalOpen] = useState(false);
  const [userData, setUserData] = useState([]);
  const [sbomFile, setSbomFile] = useState(null);
  const [fileList, setFileList] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [sortingValue, setSortingValue] = useState("asc");
  const [filetypeValue, setfiletypeValue] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [filterOptionOpen, setFilterOptionOpen] = useState(false);
  const [selectedGitorg, setselectedGitorg] = useState({});
  const [selectedGitorgRepo, setselectedGitorgRepo] = useState({});
  const [editableProjectData, seteditableProjectData] = useState();
  const [addProjectForm] = Form.useForm();
  const [editProjectForm] = Form.useForm();

  const { token, orgId } = useSelector((state) => state.auth);
  const {
    addProjectLoader,
    updateProjectLoader,
    projects,
    getProjectsLoader,
    projectsFindings,
    getProjectsfindingsLoader,
    totalProjects,
    AddProjectmodalDisplayNamesData,
    AddProjectmodalDisplayNamesLoader
  } = useSelector((state) => state.project);
  const {actionGetGitOrgsData,actionGetGitOrgRepoData,getSCMauthUrl } = useSelector((state) => state.scm);
  const dispatch = useDispatch();

  useEffect(() => {
    if(orgId && token){
      dispatch(actionGetAddProjectmodalfiletypes());
    }
  }, [orgId, token, dispatch]);

  const customEmpty = (
    <div className="flex flex-col items-center">
      <img src={noDataIcon} alt="No Data" />
      <p className="text-[#393939] text-[15px] font-montserratMedium">
        No Data Found
      </p>
    </div>
  );


  const shortingItems = [
    {
      label: <p className="text-black font-montserratMedium">Ascending</p>,
      key: "asc",
    },
    {
      label: <p className="text-black font-montserratMedium">Descending</p>,
      key: "desc",
    },
  ];

  const showScanOptionModal = () => {
    updateActiontypeofFoms("set","formActiontype","Add")
    setIsScanOptionModalOpen(true);
  };
  const handleChangeTags = (value) => {
    setSelectedItems(value);
  };

  const handleCancelScanOptionModal = () => {
    setIsScanOptionModalOpen(false);
    // setSelectedItems([])
  };

  const showAddProjectModal = () => {
    setIsAddProjectModalOpen(true);
    setIsScanOptionModalOpen(false);
    setIsUpdateProjectModalOpen(false);
  };
  const showUpdateProjectModal = () => {
    setIsUpdateProjectModalOpen(true);
    setIsAddProjectModalOpen(false);
    setIsScanOptionModalOpen(false);
  };

  useEffect(() => {
    if (isAddProjectModalOpen) {
      if (token) {
        const decodedToken = jwtDecode(token);
        setUserData(decodedToken);
      }
    }
  }, [isAddProjectModalOpen, token]);

  useEffect(() => {
    if (orgId) {
      dispatch(
        actionGetProjectsByOrgId({
          id: orgId,
          sort: sortingValue,
          currentPage,
          itemsPerPage,
        })
      );
    }
  }, [currentPage, itemsPerPage, token, orgId, sortingValue]);

  useEffect(() => {
    if (orgId) {
      dispatch(actionGetProjectsFindings(orgId));
    }
  }, [token, orgId]);

  const handleCancelAddProjectModal = () => {
    setIsAddProjectModalOpen(false);
    addProjectForm.resetFields();
    setFileList([]);
    setSbomFile(null);
  };

  const handleCancelUpdateProjectModal = () => {
    setIsUpdateProjectModalOpen(false);
    editProjectForm.resetFields();
    setFileList([]);
    setSbomFile(null);
  };

  const addFileProps = {
    name: "file",
    multiple: false,
    listType: "picture",
    action: null,
    fileList: fileList,
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },

    beforeUpload: (file) => {
      setSbomFile(file);
      // toast.success(`File selected: ${file.name}`, { duration: 5000 });
      return false;
    },
    onChange(info) {
      const { status, fileList } = info;

      if (status !== "uploading") {
        // Keep the fileList in its original form
        setFileList(fileList);
      }
      if (status === "done") {
        toast.success(`File uploaded successfully.`);
      } else if (status === "error") {
        toast.error(`File upload failed.`);
      }
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };

  const triggerGetprojectList = () => {
    dispatch(actionGetProjectsByOrgId({
        id: orgId,
        sort: sortingValue,
        currentPage,
        itemsPerPage,
      })
    );
  }

  const handleSaveProject = async (value,submit_type) => {
    let req = {
      projectVo: {
        name: value.name,
        groupOrUserName: userData?.tenant,
        type: "Type A",
        version: value.version,
        description: value.description,
        // tagList: selectedItems,
        organizationUUID: orgId,
        userUUID: userData?.userId,
      },
      sbomFile,
      displayName : value?.displayName
    };

    if(submit_type == "add"){
      dispatch(
        actionAddProject({
          req,
          handleCancel: handleCancelAddProjectModal,
          triggerGetprojectList: triggerGetprojectList,
        })
      );
    }else{
      req.projectId = editableProjectData?.id || null;
        dispatch(
        actionUpdateProject({
          req,
          handleCancel: handleCancelUpdateProjectModal,
          triggerGetprojectList: triggerGetprojectList,
        })
      );
    }
  };

  const handleSortingClick = (e) => {
    setSortingValue(e.key);
  };

  const updateActiontypeofFoms = (action,key,value) => {
    if(action == 'set') localStorage.setItem(key,value)
    if(action == 'get') return localStorage.getItem(key)
    if(action == 'reset'){
     localStorage.removeItem("projectId")
     localStorage.removeItem("formActiontype")
    }
  }

  const projectColumns = [
    {
      title: "Name",
      dataIndex: "name",
      fixed: "left",
      width: 200,
      ellipsis: true,
      align: "left",
      render: (text, data) => (
        <div className="flex gap-x-2">
          <p
            onClick={() =>
              navigate(`/${data?.id}/project-detail`, {
                state: { projectId: data.id, projectName: data.name },
              })
            }
            className="text-text-primary text-[12px] lg:text-[14px] font-montserratMedium cursor-pointer"
          >
            {text}
          </p>
          {/* <div className="flex gap-x-2 items-center border border-border py-1 px-2 rounded-xl">
            {isDarkMode ? (
              <img src={gitIconWhite} />
            ) : (
              <img src={gitIconBlack} />
            )}

            <p className="text-text-secondary text-[12px] font-montserratRegular">
              2
            </p>
          </div> */}
        </div>
      ),
    },
    {
      title: "EOL Findings",
      dataIndex: "eol",
      width: 140,
      align: "left",
      render: (eol) => (
        <div className="flex text-[#757575] items-center text-[14px] font-montserratMedium">
          {/* <span>{text.eolFindings || "0"}</span> */}
          <Flex gap="small" wrap={false}>
          <Tooltip title={eolFindingTooltips?.high} color={'#f84924'} key={'#f84924'}>
          <Progress type="circle" percent={100} size={30} 
           strokeColor="#f84924"
           format={() => <p className="font15 text-text-primary">{eol?.eolFindings?.high || 0}</p> } 
          />
          </Tooltip>
          <Tooltip title={eolFindingTooltips?.medium} color={'#BB8700'} key={'#BB8700'}>
          <Progress type="circle" percent={100} size={30} strokeColor="#BB8700" 
          format={() => <p className="font15 text-text-primary">{eol?.eolFindings?.medium || 0}</p>} />
          </Tooltip>
          <Tooltip title={eolFindingTooltips?.low} color={'#63cc75'} key={'#63cc75'}>
          <Progress type="circle" percent={100} size={30} strokeColor="#63cc75" format={() => <p className="font15 text-text-primary">{eol?.eolFindings?.low || 0}</p>} />
          </Tooltip>
        </Flex>
        </div>
      ),
    },
    {
      title: "Vulnerabilities",
      width: 400,
      dataIndex: "findingsMetrics",
      render: (findingsMetrics) => (
        // <div className="border border-border rounded-[5px] flex">
        //   <div className="flex text-text-secondary items-center border-r border-r-border text-[14px] font-montserratMedium">
        //     <p className="py-1 px-2">
        //       {findingsMetrics?.critical || 0} {/* Display critical value */}
        //     </p>
        //     <p className="py-1">|</p>
        //     <p className="py-1 px-2">C</p>
        //   </div>
        //   <div className="flex text-text-secondary items-center border-r border-r-border text-[14px] font-montserratMedium">
        //     <p className="py-1 px-2">
        //       {findingsMetrics?.high || 0} {/* Display high value */}
        //     </p>
        //     <p className="py-1">|</p>
        //     <p className="py-1 px-2 text-[#CE5019]">H</p>
        //   </div>
        //   <div className="flex text-text-secondary items-center border-r border-r-border text-[14px] font-montserratMedium">
        //     <p className="py-1 px-2">
        //       {findingsMetrics?.medium || 0} {/* Display medium value */}
        //     </p>
        //     <p className="py-1">|</p>
        //     <p className="py-1 px-2 text-[#D68001]">M</p>
        //   </div>
        //   <div className="flex text-text-secondary items-center text-[14px] font-montserratMedium">
        //     <p className="py-1 px-2">
        //       {findingsMetrics?.low || 0} {/* Display low value */}
        //     </p>
        //     <p className="py-1">|</p>
        //     <p className="py-1 px-2">L</p>
        //   </div>
        // </div>
        <div className="font-montserratMedium text-[15px] flex gap-x-1">
          <Tooltip title={Vulnerabilities?.critical} color={'#BB0100'} key={'#BB0100'}>
          <div className="flex gap-x-0.5">
            <div className="h-[30px] w-[35px] bg-[#F5D9D9] text-[#BB0100] flex justify-center items-center rounded-l-md">
              <p>{findingsMetrics?.critical || 0}</p>
            </div>
            <div className="h-[30px] w-[35px] text-white bg-[#BB0100] flex justify-center items-center rounded-r-md">
              <p>C</p>
            </div>
          </div>
          </Tooltip>
          <Tooltip title={Vulnerabilities?.high} color={'#BB5A00'} key={'#BB5A00'}>
          <div className="flex gap-x-0.5">
            <div className="h-[30px] w-[35px] bg-[#F5E6D9] text-[#BB5A00] flex justify-center items-center rounded-l-md">
              <p>{findingsMetrics?.high || 0}</p>
            </div>
            <div className="h-[30px] w-[35px] text-white bg-[#BB5A00] flex justify-center items-center rounded-r-md">
              <p>H</p>
            </div>
          </div>
          </Tooltip>
          <Tooltip title={Vulnerabilities?.medium} color={'#BB8700'} key={'#BB8700'}>
          <div className="flex gap-x-0.5">
            <div className="h-[30px] w-[35px] bg-[#F5EDD9] text-[#BB8700] flex justify-center items-center rounded-l-md">
              <p>{findingsMetrics?.medium || 0}</p>
            </div>
            <div className="h-[30px] w-[35px] text-white bg-[#BB8700] flex justify-center items-center rounded-r-md">
              <p>M</p>
            </div>
          </div>
          </Tooltip>
          <Tooltip title={Vulnerabilities?.low} color={'#9F9F9F'} key={'#9F9F9F'}>
          <div className="flex gap-x-0.5">
            <div className="h-[30px] w-[35px] bg-[#E3E3E3] text-[#9F9F9F] flex justify-center items-center rounded-l-md">
              <p>{findingsMetrics?.low || 0}</p>
            </div>
            <div className="h-[30px] w-[35px] text-white bg-[#9F9F9F] flex justify-center items-center rounded-r-md">
              <p>L</p>
            </div>
          </div>
          </Tooltip>
          <Tooltip title={Vulnerabilities?.unassigned} color={'#ff7685'} key={'#ff7685'}>
          <div className="flex gap-x-0.5">
            <div className="h-[30px] w-[35px] bg-[#f8b1b863] text-[#f84054d6] flex justify-center items-center rounded-l-md">
              <p>{findingsMetrics?.unassigned || 0}</p>
            </div>
            <div className="h-[30px] w-[35px] text-white bg-[#ff7685] flex justify-center items-center rounded-r-md">
              <p>U</p>
            </div>
          </div>
          </Tooltip>
        </div>
      ),
    },
    {
      title: "Scan Status",
      dataIndex: "scanStatus",
      width: 300,
      align: "left",
      render: (text,rec) => (
        <div className="flex text-[#757575] items-center text-[14px] font-montserratMedium">
            {/* console.log(Intl.DateTimeFormat().resolvedOptions().timeZone); */}
          <Tooltip title={rec?.lastModifiedDate}>
          <ClockCircleFilled /> 
          </Tooltip>
          <p className="pl-2">{text || "-"}</p>
        </div>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: 70,
      fixed: "center",
      render: (text, data) => (
        <>
          <div className="flex gap-x-2 items-center cursor-pointer">
             <div>
             <Tooltip title={data?.modeOfCreation}>
             <EditOutlined onClick={()=> {
              let modaOfcreation = data?.modeOfCreation?.toLowerCase();
              updateActiontypeofFoms("set","formActiontype","edit");
              updateActiontypeofFoms("set","projectId",data?.id);
              seteditableProjectData(data);
              if(modaOfcreation == 'manual') showUpdateProjectModal();
              if(modaOfcreation == 'github') handleEditGithubProjectAdd();
            }} />
            </Tooltip>
            </div>
            {/*<div>
              <img src={paushIcon} alt="icon" />
            </div>
            <div>
              <img src={watchIcon} alt="icon" />
            </div>
            <div className="text-[#bfbfbf]">|</div> */}
            <div className="pl-1">
              <img src={deleteIcon} alt="icon" onClick={()=>{
                Swal.fire({
                  title: "Do you want to delete the project?",
                  // showDenyButton: true,
                  showCancelButton: true,
                  confirmButtonText: "Yes",
                  // denyButtonText: `Don't Delete`
                }).then((result) => {
                  if (result.isConfirmed) {
                    dispatch(action_deleteProject({id:data?.id}));
                  } 
                  // else if (result.isDenied) {
                  // Swal.fire("Project is not Deleted", "", "info");
                  // }
                });
              }} />
            </div>
          </div>
        </>
      ),
    },
  ];

  const onChooseGitorg = (checkedValues) => {
    console.log('checked = ', checkedValues);
    setselectedGitorg(checkedValues?.target?.value)
  };

  const onChooseGitorgRepo = (checkedValues) => {
    setselectedGitorgRepo(checkedValues?.target?.value)
  };

  const toProjectPage = () => {
    navigate('/project', { replace: true, state: { reload: true } });
    window.location.reload();  // This forces a full reload
  };
  
  const handleGitProjectCardclick = () => {
    setIsScanOptionModalOpen(false);
    dispatch(actionSCMauth());
  };

  const getToekn = () =>{
    const decodedToken = jwtDecode(token);
    setUserData(decodedToken);
    return decodedToken;
  }

  const handleEditGithubProjectAdd = () => handleGitProjectCardclick();

  useEffect(() => {
    // Check if the URL has the 'code' parameter
    const queryParams = new URLSearchParams(location?.search);
    const code = queryParams?.get('code');
    if (code) { 
      navigate('/project', { replace: true });
      dispatch(actionSCMcallback({code,'userData': getToekn(),orgId,selectedGitorg}));
    }
    
    return () => {
      dispatch(setGitOrgsData([])); 
    };
  }, [location?.search,navigate]);

  useEffect(() => {
    if(actionGetGitOrgsData?.length) setIsGitProjectModalOpen(true)
  }, [actionGetGitOrgsData]);  

  return (
    <>
      <Spin spinning={getProjectsLoader || getProjectsfindingsLoader}>
        <div ref={cardsContainerRef}>
        <Row gutter={[16, 24]} justify="space-between">
          <Col xs={24} sm={12} md={12} lg={6}>
            <div className="border px-6 py-3 text-text-primary bg-box-secondary rounded-[10px] border-blue-border">
              <h1 className="font-montserratMedium text-[17px]">
                {projectsFindings ? projectsFindings.projects : "0"}
              </h1>
              <p className="pt-2 text-[15px]">Projects</p>
              {/* <div className="pt-2" style={{ width: "100%" }}>
                <HighchartsReact
                  highcharts={Highcharts}
                  options={chartOptions}
                />
              </div> */}
            </div>
          </Col>

          <Col xs={24} sm={12} md={12} lg={6}>
            <div className="border px-6 py-3 text-text-primary bg-box-secondary rounded-[10px] border-red-border">
              <h1 className="font-montserratMedium text-[17px]">
                {projectsFindings ? projectsFindings.riskScore : "0"}
              </h1>
              <p className="pt-2 text-[15px]">Risk Score</p>
              {/* <div className="pt-2" style={{ width: "100%" }}>
                <Progress percent={40} size="small" showInfo={false} />
              </div> */}
            </div>
          </Col>

          <Col xs={24} sm={12} md={12} lg={6}>
            <div className="border px-6 py-3 text-text-primary bg-box-secondary rounded-[10px] border-purple-border">
              <h1 className="font-montserratMedium text-[17px]">
                {projectsFindings ? projectsFindings.vulnerableProjects : "0"}
              </h1>
              <p className="pt-2 text-[15px]">Vulnerable Projects</p>
              {/* <div className="pt-2" style={{ width: "100%" }}>
                <Progress percent={70} size="small" showInfo={false} />
              </div> */}
            </div>
          </Col>

          <Col xs={24} sm={12} md={12} lg={6}>
            <div className="border px-6 py-3 text-text-primary bg-box-secondary rounded-[10px] border-pink-border">
              <h1 className="font-montserratMedium text-[17px]">
                {projectsFindings ? projectsFindings.supplyChainFindings : "0"}
              </h1>
              <p className="pt-2 text-[15px]">Supply Chain Findings</p>
              {/* <div className="pt-2" style={{ width: "100%" }}>
                <Progress percent={90} size="small" showInfo={false} />
              </div> */}
            </div>
          </Col>
        </Row>
        <div className="my-4 flex justify-between items-center">
          <div className="flex gap-x-2 items-center">
            <p className="text-[15px] font-montserratMedium text-text-primary">
              Projects
            </p>
            {/* <div className="border border-border h-6 w-6 rounded-full flex justify-center items-center text-[12px] text-text-secondary">
              <p>3</p>
            </div> */}
            <div>
              <Button
                onClick={showScanOptionModal}
                type="primary"
                className="sm:px-[15px]  sm:py-[4px] px-[12px] py-[3px] text-[12px] font-montserratMedium"
              >
                Add Project
              </Button>
            </div>
          </div>
          <div>
            <div className="gap-x-2 flex items-center">
              {/* <Input
                placeholder="Search"
                allowClear
                className="w-[300px] md:w-[350px] lg:w-[400px] py-2 border border-border hover:border-border bg-transparent hover:bg-transparent text-text-primary"
                prefix={
                  <img src={searchIconGrey} alt="icon" className="mr-2" />
                }
              /> */}
              {/* <Dropdown
                open={filterOptionOpen}
                className="ant-dropdown-custom"
                menu={{
                  items: filterItems, // Correctly pass the items in an object with `items` property
                }}
                trigger={["click"]}
              >
                <div onClick={handleToggleDropdown} className="cursor-pointer">
                  <div className="flex items-center px-2 gap-x-2 h-[40px] rounded-md border border-border">
                    <img src={filterIcon} alt="filter" />
                    <p className="text-text-secondary">Add Filter</p>
                    <img src={dropdownIcon} alt="filter" />
                  </div>
                </div>
              </Dropdown> */}
              <Dropdown
                className="ant-dropdown-custom cursor-pointer"
                menu={{
                  items: shortingItems, // Correctly pass the items in an object with `items` property
                  onClick: handleSortingClick,
                }}
                trigger={["click"]}
              >
                <div>
                  <div className="flex items-center px-2 gap-x-2 h-[40px] rounded-md border border-border">
                    <img src={shortingIcon} alt="filter" />
                    <p className="text-text-secondary">Sort by order</p>
                    <img src={dropdownIcon} alt="filter" />
                  </div>
                </div>
              </Dropdown>
            </div>
          </div>
        </div>
        </div>
        <div >
          {/* <div className="bg-box-secondary rounded-md border border-border">
            {projects && projects.length > 0 ? (
              projects.map((project, index) => (
                <div
                  key={index}
                  className={`flex justify-between items-center py-3 px-3 ${
                    index !== projects.length - 1
                      ? "border-b border-b-border"
                      : ""
                  }`}
                >
                  <div className="flex gap-x-2">
                    <p
                      onClick={() =>
                        navigate("/project-detail", {
                          state: { projectId: project.id },
                        })
                      }
                      className="text-text-primary text-[16px] lg:text-[18px] font-montserratMedium cursor-pointer"
                    >
                      {project?.name || "-"}
                    </p>
                    <div className="flex gap-x-2 items-center border border-border py-1 px-2 rounded-xl">
                      {isDarkMode ? (
                        <img src={gitIconWhite} />
                      ) : (
                        <img src={gitIconBlack} />
                      )}

                      <p className="text-text-secondary text-[12px] font-montserratRegular">
                        2
                      </p>
                    </div>
                  </div>
                  <div className="flex gap-x-3 lg:gap-x-6">
                    <div className="border border-border rounded-[5px] flex">
                      <div className="flex text-text-secondary items-center border-r border-r-border text-[14px] font-montserratMedium">
                        <p className="py-1 px-2">
                          {project?.findingsMetrics?.critical}
                        </p>
                        <p className="py-1">|</p>
                        <p className="py-1 px-2">C</p>
                      </div>
                      <div className="flex text-text-secondary items-center border-r border-r-border text-[14px] font-montserratMedium">
                        <p className="py-1 px-2">
                          {project?.findingsMetrics?.high}
                        </p>
                        <p className="py-1">|</p>
                        <p className="py-1 px-2 text-[#CE5019]">H</p>
                      </div>
                      <div className="flex text-text-secondary items-center border-r border-r-border text-[14px] font-montserratMedium">
                        <p className="py-1 px-2">
                          {project?.findingsMetrics?.medium}
                        </p>
                        <p className="py-1">|</p>
                        <p className="py-1 px-2 text-[#D68001]">M</p>
                      </div>
                      <div className="flex text-text-secondary items-center text-[14px] font-montserratMedium">
                        <p className="py-1 px-2">
                          {project?.findingsMetrics?.low}
                        </p>
                        <p className="py-1">|</p>
                        <p className="py-1 px-2">L</p>
                      </div>
                    </div>
                    <Tooltip
                      title="EOL Findings"
                      placement="bottom"
                      color={isDarkMode ? "#494949" : "black"}
                    >
                      <div className="flex gap-x-2 items-center">
                        <div>
                          <img src={projectSearchIcon} alt="icon" />
                        </div>
                        <div className="flex text-[#757575] items-center text-[14px] font-montserratMedium">
                          <p>{project?.eol?.eolFindings || "0"}</p>
                        </div>
                      </div>
                    </Tooltip>
                    <Tooltip
                      title="Scan Status"
                      placement="bottom"
                      color={isDarkMode ? "#494949" : "black"}
                    >
                      <div className="flex gap-x-2 items-center">
                        <div>
                          <img src={projectUserIcon} alt="icon" />
                        </div>
                        <div className="flex text-[#757575] items-center text-[14px] font-montserratMedium">
                          <p>{project?.scanStatus || "-"}</p>
                        </div>
                      </div>
                    </Tooltip>
                    <div className="flex gap-x-2 items-center">
                      <div>
                        <img src={playIcon} alt="icon" />
                      </div>
                      <div>
                        <img src={paushIcon} alt="icon" />
                      </div>
                      <div>
                        <img src={watchIcon} alt="icon" />
                      </div>
                      <div className="text-[#bfbfbf]">|</div>
                      <div>
                        <img src={deleteIcon} alt="icon" />
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="justify-center flex flex-col items-center py-4 lg:py-8">
                <div>
                  <img src={noDataIcon} alt="no data" />
                </div>
                <p className="text-[#393939] text-[15px] font-montserratMedium">
                  No Data Found
                </p>
              </div>
            )}
          </div> */}
            <Tablec
            columns={projectColumns} 
            dataSource={projects} 
            customEmpty={customEmpty} 
            cardsContainerRef={cardsContainerRef} 
            pagination={false}
            rowKey={"name"}
          />
        </div>

        {projects && projects.length > 0 && (
          <div className="flex justify-end">
            <Pagination
              hideOnSinglePage
              current={currentPage}
              pageSize={itemsPerPage}
              total={totalProjects}
              onChange={(page, pageSize) => {
                setCurrentPage(page); // Update current page
                setItemsPerPage(pageSize); // Optionally, handle items per page change
              }}
            />
          </div>
        )}
        <Modal
          centered
          destroyOnClose={handleCancelAddProjectModal}
          onOk={handleCancelAddProjectModal}
          onCancel={handleCancelAddProjectModal}
          footer={false}
          width={650}
          open={isAddProjectModalOpen}
        >
          <h1 className="text-text-primary text-[20px] lg:text-[23px] font-font-montserratRegular text-center">
            Add Project
          </h1>
          <Form
            initialValues={{
              groupOrUserName: userData && userData.tenant,
            }}
            form={addProjectForm}
            onFinish={(values)=>handleSaveProject(values,"add")}
            layout="vertical"
            className="mt-[20px] mb-0"
          >
            <Form.Item
              name="name"
              label="Name"
              className="mb-[12px]"
              size="medium"
              rules={[
                {
                  required: true,
                  message: "Please input project name!",
                },
              ]}
            >
              <Input
                style={{ background: "white" }}
                placeholder="Enter Project Name"
                className="py-2 border border-border !bg-transparent hover:bg-transparent text-text-primary"
              />
            </Form.Item>

            {/* <Form.Item
              name="groupOrUserName"
              label="Group or User Name"
              className="mb-[12px]"
              size="medium"
              rules={[
                {
                  required: true,
                  message: "Please input the group or user name!",
                },
              ]}
            >
              <Input
                style={{ background: "white" }}
                disabled
                defaultValue={userData?.tenant}
                placeholder="Enter Group or User Name"
                className="py-2 border border-border !bg-transparent hover:bg-transparent text-text-primary"
              />
            </Form.Item> */}

            {/* <Form.Item
              name="type"
              label="Type"
              className="mb-[12px]"
              size="medium"
              rules={[
                {
                  required: false,
                  message: "Please input the type!",
                },
              ]}
            >
              <Input
                style={{ background: "white" }}
                placeholder="Enter Type"
                className="py-2 border border-border !bg-transparent hover:bg-transparent text-text-primary"
              />
            </Form.Item> */}
            <Form.Item
              name="version"
              label="Version"
              className="mb-[12px]"
              size="medium"
              rules={[
                {
                  required: false,
                  message: "Please input the version!",
                },
              ]}
            >
              <Input
                style={{ background: "white" }}
                placeholder="Enter Version"
                className="py-2 border border-border !bg-transparent hover:bg-transparent text-text-primary"
              />
            </Form.Item>
            <Form.Item
              name="description"
              label="Description"
              className="mb-[12px]"
              size="medium"
              rules={[
                {
                  required: false,
                  message: "Please input the type!",
                },
              ]}
            >
              {/* <Input
                style={{ background: "white" }}
                placeholder="Enter Type"
                className="py-2 border border-border !bg-transparent hover:bg-transparent text-text-primary"
              /> */}
              <Input.TextArea
                placeholder="Enter Description"
                className="py-2 border border-border !bg-transparent hover:bg-transparent text-text-primary"
              />
            </Form.Item>

            {/* <Form.Item
            name="highValue"
            label="High Value"
            className="mb-[12px]"
            size="medium"
            rules={[
              {
                required: false,
                message: "Please input the high value!",
              },
            ]}
          >
            <Input
              style={{ background: "white" }}
              placeholder="Enter High Value"
              className="py-2 border border-border !bg-transparent hover:bg-transparent text-text-primary"
            />
          </Form.Item>

          <Form.Item
            name="mediumValue"
            label="Medium Value"
            className="mb-[12px]"
            size="medium"
            rules={[
              {
                required: false,
                message: "Please input the medium value!",
              },
            ]}
          >
            <Input
              style={{ background: "white" }}
              placeholder="Enter Medium Value"
              className="py-2 border border-border !bg-transparent hover:bg-transparent text-text-primary"
            />
          </Form.Item>

          <Form.Item
            name="lowValue"
            label="Low Value"
            className="mb-[12px]"
            size="medium"
            rules={[
              {
                required: false,
                message: "Please input the low value!",
              },
            ]}
          >
            <Input
              style={{ background: "white" }}
              placeholder="Enter Low Value"
              className="py-2 border border-border !bg-transparent hover:bg-transparent text-text-primary"
            />
          </Form.Item> */}
            <Form.Item
              name="sbomFile"
              label="Upload File"
              className="mb-[12px]"
              size="medium"
              rules={[
                {
                  required: false,
                  message: "Please upload file!",
                },
              ]}
            >
              <Dragger
                {...addFileProps}
                onRemove={(file) => {
                  setSbomFile(null);
                  setFileList((prev) =>
                    prev.filter((item) => item.uid !== file.uid)
                  );
                }}
                accept="*"
                maxCount={1}
              >
                <div className="flex justify-center gap-x-2 py-2 lg:py-4">
                  <img src={uploadIcon} alt="upload icon" />
                  <p className="text-text-primary text-[#808080] text-[14px] font-montserratRegular">
                    Upload File
                  </p>
                </div>
              </Dragger>
            </Form.Item>
            <Form.Item
              name="displayName"
              label="File Type"
              className="mb-[12px]"
              size="medium"
              rules={[
                {
                  required: fileList?.length > 0 ? true : false,
                  message: "Please select file type",
                },
              ]}
            >
               {/* <Dropdown
                className="ant-dropdown-custom cursor-pointer"
                menu={{
                  items: AddProjectmodalDisplayNamesData, // Correctly pass the items in an object with `items` property
                  onClick: handleNameselect,
                }}
                trigger={["click"]}
              >
                <div>
                  <div className="flex items-center px-2 gap-x-2 h-[40px] rounded-md border border-border">
                    <p className="text-text-secondary">File Type</p>
                  </div>
                </div>
              </Dropdown> */}

              <Select
                options={AddProjectmodalDisplayNamesData}
               className="remove-arrow tags-of-project"           
                style={{ width: "100%" }}
                placeholder="Select file type"
                value={filetypeValue}
                onChange={(value, selectedType) => {
                  setfiletypeValue(selectedType);
                }}
                notFoundContent={null}
                popupClassName="add-project-filtype-dropdown"
                allowClear
              />

            </Form.Item>
            <Form.Item
              label="Tags"
              name="tagList"
              rules={[
                {
                  required: false,
                  message: "Please input tags!",
                },
              ]}
            >
              <Select
                open={true}
                className="remove-arrow tags-of-project"
                popupClassName="tag-select"
                multiple={true}
                showSearch={false}
                mode="tags"
                style={{ width: "100%" }}
                placeholder="Enter tags"
                value={selectedItems}
                onChange={handleChangeTags}
                notFoundContent={null}
              />
            </Form.Item>

            <div className="flex justify-end mt-[40px]">
              <Form.Item className="mb-0">
                <button
                  onClick={() => handleCancelAddProjectModal()}
                  type="button"
                  className="rounded-[6px] text-grey-button border border-grey-button hover:text-grey-button min-h-[40px] min-w-[80px] text-[15px] font-montserratMedium"
                >
                  Cancel
                </button>
              </Form.Item>

              <div className="ml-4">
                <Form.Item className="mb-0">
                  <Button
                    loading={addProjectLoader}
                    type="primary"
                    className=" min-h-[40px] min-w-[120px] text-[15px] font-montserratMedium"
                    htmlType="submit"
                  >
                    Create Project
                  </Button>
                </Form.Item>
              </div>
            </div>
          </Form>
        </Modal>
        <Modal
          centered
          destroyOnClose={handleCancelUpdateProjectModal}
          onOk={handleCancelUpdateProjectModal}
          onCancel={handleCancelUpdateProjectModal}
          footer={false}
          width={650}
          open={isUpdateProjectModalOpen}
        >
          <h1 className="text-text-primary text-[20px] lg:text-[23px] font-font-montserratRegular text-center">
            Update Project
          </h1>
          <Form
            initialValues={{
              name: editableProjectData?.name || '',
            }}
            form={editProjectForm}
            onFinish={(values)=>handleSaveProject(values,"edit")}
            layout="vertical"
            className="mt-[20px] mb-0"
          >
            <Form.Item
              name="name"
              label="Name"
              className="mb-[12px]"
              size="medium"
              rules={[
                {
                  required: true,
                  message: "Please input project name!",
                },
              ]}
            >
              <Input
                disabled
                style={{ background: "white" }}
                placeholder="Enter Project Name"
                className="py-2 border border-border bg-gray-50 text-text-primary"
              />
            </Form.Item>
            <Form.Item
              name="version"
              label="Version"
              className="mb-[12px]"
              size="medium"
              rules={[
                {
                  required: false,
                  message: "Please input the version!",
                },
              ]}
            >
              <Input
                style={{ background: "white" }}
                placeholder="Enter Version"
                className="py-2 border border-border !bg-transparent hover:bg-transparent text-text-primary"
              />
            </Form.Item>
            <Form.Item
              name="description"
              label="Description"
              className="mb-[12px]"
              size="medium"
              rules={[
                {
                  required: false,
                  message: "Please input the type!",
                },
              ]}
            >
              <Input.TextArea
                placeholder="Enter Description"
                className="py-2 border border-border !bg-transparent hover:bg-transparent text-text-primary"
              />
            </Form.Item>
            <Form.Item
              name="sbomFile"
              label="Upload File"
              className="mb-[12px]"
              size="medium"
              rules={[
                {
                  required: false,
                  message: "Please upload file!",
                },
              ]}
            >
              <Dragger
                {...addFileProps}
                onRemove={(file) => {
                  setSbomFile(null);
                  setFileList((prev) =>
                    prev.filter((item) => item.uid !== file.uid)
                  );
                }}
                accept="*"
                maxCount={1}
              >
                <div className="flex justify-center gap-x-2 py-2 lg:py-4">
                  <img src={uploadIcon} alt="upload icon" />
                  <p className="text-text-primary text-[#808080] text-[14px] font-montserratRegular">
                    Upload File
                  </p>
                </div>
              </Dragger>
            </Form.Item>
            <Form.Item
              name="displayName"
              label="File Type"
              className="mb-[12px]"
              size="medium"
              rules={[
                {
                  required: fileList?.length > 0 ? true : false,
                  message: "Please select file type",
                },
              ]}
            >
              <Select
                options={AddProjectmodalDisplayNamesData}
               className="remove-arrow tags-of-project"           
                style={{ width: "100%" }}
                placeholder="Select file type"
                value={filetypeValue}
                onChange={(value, selectedType) => {
                  setfiletypeValue(selectedType);
                }}
                notFoundContent={null}
                popupClassName="add-project-filtype-dropdown"
                allowClear
              />

            </Form.Item>
            <Form.Item
              label="Tags"
              name="tagList"
              rules={[
                {
                  required: false,
                  message: "Please input tags!",
                },
              ]}
            >
              <Select
                open={true}
                className="remove-arrow tags-of-project"
                popupClassName="tag-select"
                multiple={true}
                showSearch={false}
                mode="tags"
                style={{ width: "100%" }}
                placeholder="Enter tags"
                value={selectedItems}
                onChange={handleChangeTags}
                notFoundContent={null}
              />
            </Form.Item>

            <div className="flex justify-end mt-[40px]">
              <Form.Item className="mb-0">
                <button
                  onClick={() => handleCancelUpdateProjectModal()}
                  type="button"
                  className="rounded-[6px] text-grey-button border border-grey-button hover:text-grey-button min-h-[40px] min-w-[80px] text-[15px] font-montserratMedium"
                >
                  Cancel
                </button>
              </Form.Item>

              <div className="ml-4">
                <Form.Item className="mb-0">
                  <Button
                    loading={updateProjectLoader}
                    type="primary"
                    className=" min-h-[40px] min-w-[120px] text-[15px] font-montserratMedium"
                    htmlType="submit"
                  >
                    Update Project
                  </Button>
                </Form.Item>
              </div>
            </div>
          </Form>
        </Modal>
        <Modal
          centered
          onOk={() => handleCancelScanOptionModal()}
          onCancel={() => handleCancelScanOptionModal()}
          footer={false}
          width={850}
          open={isScanOptionModalOpen}
        >
          <h1 className="text-text-primary text-[20px] lg:text-[23px] font-montserratRegular text-center">
            Where do you want to scan ?
          </h1>
          <div className="py-3 flex gap-x-2 lg:gap-x-6">
            <div
              onClick={showAddProjectModal}
              className={`cursor-pointer p-2 w-[250px] h-[260px] lg:w-[280px] lg:h-[290px] border border-border rounded-[10px] flex flex-col items-center justify-center gap-y-2 ${
                isDarkMode ? "bg-[#0D0D0D]" : "bg-white"
              }`}
            >
              <img src={sbomFileIcon} alt="Upload SBOM File" />
              <p className="text-text-primary text-[18px] lg:text-[20px] font-montserratMedium">
                Upload File
              </p>
            </div>
            <div
              onClick={handleGitProjectCardclick}
              className={`cursor-pointer p-2 w-[250px] h-[260px] lg:w-[280px] lg:h-[290px] border border-border rounded-[10px] flex flex-col items-center justify-center gap-y-2 ${
                isDarkMode ? "bg-[#0D0D0D]" : "bg-white"
              }`}
            >
              <img src={gitIconBlack} style={{height:'50px',color:"blue"}} alt="Upload SBOM File" />
              <p className="text-text-primary text-[18px] lg:text-[20px] font-montserratMedium">
                Git
              </p>
            </div>
            <div
              className={`cursor-pointer p-2 w-[250px] h-[260px] lg:w-[280px] lg:h-[290px] border border-border rounded-[10px] flex flex-col items-center justify-center gap-y-2 ${
                isDarkMode ? "bg-[#0D0D0D]" : "bg-white"
              } opacity-50 cursor-not-allowed pointer-events-none`}
            >
              <img src={cliIcon} alt="CLI" />
              <p className="text-text-primary text-[18px] lg:text-[20px] font-montserratMedium">
                CLI
              </p>
              <p className="text-text-primary text-center text-[13px] lg:text-[15px] font-montserratRegular">
                Scan locally with the Neotrack CLI and view findings in the
                Neotrack AppSec Platform.
              </p>
            </div>
            <div
              className={`cursor-pointer p-2 w-[250px] h-[260px] lg:w-[280px] lg:h-[290px] border border-border rounded-[10px] flex flex-col items-center justify-center gap-y-2 ${
                isDarkMode ? "bg-[#0D0D0D]" : "bg-white"
              } opacity-50 pointer-events-none`}
            >
              <img src={cicdIcon} alt="CI/CD" />
              <p className="text-text-primary text-[18px] lg:text-[20px] font-montserratMedium">
                CI/CD
              </p>
              <p className="text-text-primary text-center text-[13px] lg:text-[15px] font-montserratRegular">
                Scan continuously using GitHub Actions, GitLab CI/CD, CircleCI
                or other CI providers
              </p>
            </div>
          </div>
        </Modal>
        <Modal
          classNames={{ body: 'gitOrgTemplate-height eol-scroll' }}
          title={"Git Organizaions"}
          centered
          open={isGitProjectModalOpen}
          okText={'Save'}
          onOk={() => {
            setIsGitProjectModalOpen(false);
            setIsGitReposModalOpen(true);
            dispatch(actionGetGitOrgRepo({selectedGitorg,orgId}));
          }}
          onCancel={() => setIsGitProjectModalOpen(false)}
          width={{ xs: '60%',sm: '60%',md: '50%',lg: '50%',xl: '40%',xxl: '40%'}} >
            <div className="wrapper pb-2">
              <Radio.Group
                name="gitOrg"
                value={selectedGitorg}
                style={{
                  display: "block",
                 }}
                onChange={(e)=> onChooseGitorg(e)} >
                <Row gutter={[16, 16]} style={{
                 display: "block", 
                 rowGap : 25,
                 marginLeft:0,
                 marginRight:0 
                }}>
                {actionGetGitOrgsData && actionGetGitOrgsData?.map((gitorgs, index) => (
                    <Col className="mb-2" key={index} span={8} xs={24} sm={24} md={24} lg={24} xl={24} > 
                      <Radio value={gitorgs}>
                      {gitorgs?.login}
                      </Radio>
                    </Col>
                  ))}
                  </Row>
              </Radio.Group>
            </div>
         </Modal>
        <Modal
          classNames={{ body: 'gitOrgTemplate-height eol-scroll' }}
          title={`${selectedGitorg?.login} > Repositories`}
          centered
          open={isGitReposModalOpen}
          okText={'Save'}
          onOk={() => {
            setIsGitReposModalOpen(false);
            if(updateActiontypeofFoms("get","formActiontype") == "edit"){
              let projectId = updateActiontypeofFoms("get","projectId");
              dispatch(actionPutGitUpdateFile({projectId,orgId,toProjectPage}));
            }else{
              dispatch(actionGetGitFileUpload({selectedGitorgRepo,orgId,toProjectPage}));
            }
          }}
          onCancel={() => setIsGitReposModalOpen(false)}
          width={{ xs: '90%',sm: '80%',md: '70%',lg: '80%',xl: '70%',xxl: '70%'}} >
            <div className="wrapper pb-2">
              <Radio.Group
                name="gitOrgRepo"
                value={selectedGitorgRepo}
                onChange={(e)=> onChooseGitorgRepo(e)} >
                <Row gutter={[16, 16]} justify="start" wrap={true} style={{
                 rowGap : 25,
                //  columnGap : 10,
                 marginLeft:0,
                 marginRight:0 
                }}>
                  {actionGetGitOrgRepoData && actionGetGitOrgRepoData.map((gitorgrepo, index) => (
                    <Col key={index} span={8} xs={24} sm={12} md={12} lg={8} xl={8} > 
                      <Radio value={gitorgrepo}>
                        {gitorgrepo?.name}
                      </Radio>
                    </Col>
                  ))}
                </Row>
              </Radio.Group>
            </div>
         </Modal>
      </Spin>
    </>
  );
};

export default Project;
