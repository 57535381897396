import React, { useState, useEffect, useRef } from 'react';
import { DeleteFilled, QuestionCircleOutlined } from "@ant-design/icons";

import './Carousel.css';
// import eolcard from "../../../assets/img/eolcard.svg";
import defl2 from "../../../assets/img/defl2.jpeg";
import { highlightText } from '../../helper/common';
import { Button, Popconfirm } from 'antd';
import Swal from 'sweetalert2';
// import Title from 'antd/es/typography/Title';

const CategoryComp = (props) => {
  const [open, setOpen] = useState(false);
  // console.log("selectProductOptions",props?.selectProductOptions)

  return (
    <>
      {props?.selectProductOptions && props?.selectProductOptions.map((category, index) => {
        return (
          <div key={index} className={`wrapper ${category?.products && category?.products?.length > 0 ? 'py-2':'hidden'}`}>
            {category?.products && category?.products?.length > 0 ? (
              <span class="text-black-200 text-sm font-montserratRegular">{category?.category}</span>
            ):(null)}
            <ul className="carousel mt-1">
              {category?.products && category?.products.map((product, productIndex) => {
                return (
                  <li className='card_wrapper' key={productIndex}>
                    <div className='card_delete_wrapper'>
                      <div className='card_delete_container'>
                      {props?.from == "old" && 
                        <Button 
                        className='card_del_icon' 
                        size='small' 
                        icon={<DeleteFilled style={{color:"black"}} />}
                        onClick={()=>{
                          Swal.fire({
                            title: "Do you want to delete the selection ?",
                            showCancelButton: true,
                            confirmButtonText: "Yes",
                          }).then((result) => {
                            if (result.isConfirmed) {
                              props?.onCardDelete({...product,from:props.from})
                            } 
                          })
                        }}></Button>}
                      </div>
                    </div>
                    <div className="card"  onClick={() => props?.onCardClick({...product,from:props.from})}>
                    <div className="img">
                      <img className='fade-in-image' src={product?.imageUrl ? product?.imageUrl : defl2} alt="" />
                    </div>
                    {/* <h2>{product?.description || product?.name}</h2> */}
                    <h2 dangerouslySetInnerHTML={{
                        __html: highlightText(product?.description || product?.name, props?.searchValue )
                      }}></h2>
                      </div>
                  </li>
                )
              })}
            </ul>
          </div>
        )
      })}
    </>
  );
};

export default CategoryComp;
