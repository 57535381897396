import { createBrowserRouter } from "react-router-dom";
import Login from "../components/auth/Login";
import Dashboard from "../components/dashboard/Dashboard";
import Root from "../components/common/Root";
import { PrivateRoute, PublicRoute } from "../components/helper/RouteHoc";
import Project from "../components/project/Project";
import MainDashboard from "../components/dashboard/MainDashboard";
import SupplyChain from "../components/supplyChain/SupplyChain";
import ProjectDetail from "../components/project/ProjectDetail";
import AddService from "../components/project/AddService";
import NotFound from "../components/common/NotFound";
import EndofLife from "../components/project/EndofLife";
import { CollapsedProvider } from "../contexts/CollapsedContext";

const authPaths = ["/auth/google", "/auth/github"];
const authRoutes = authPaths.map(path => ({
  path,
  element: <Login />
}));

export const router = createBrowserRouter([
  {
    element: <PublicRoute />,   // Public routes
    children: [
      {
        path: "/auth",
        element: <Login />,
      },
      ...authRoutes
    ],
  },
  {
    element: <PrivateRoute component={<CollapsedProvider><Root /></CollapsedProvider>} />, // Private routes (accessible only after login)
    children: [
      {
        path: "/",
        element: <MainDashboard />,
      },
      // {
      //   path: "/main-dashboard",
      //   element: <MainDashboard />,
      // },
      {
        exact :true,
        path: "/project",
        element: <Project />,
      },
      {
        path: "/create-project",
        element: <Project />,
      },
      {
        path: "/supply-chain",
        element: <SupplyChain />,
      },
      {
        path: "/:projectName/project-detail",
        element: <ProjectDetail />,
      },
      {
        path: "/add-service",
        element: <AddService />,
      },
      {
        path: "/end-of-life",
        element: <EndofLife />,
      },
    ],
  },
  {
    path: "*", // Fallback route for any undefined paths
    element: <NotFound/>,
  },
]);
