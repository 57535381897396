import { createAsyncThunk } from "@reduxjs/toolkit";
import { BASE_URL } from "../../config/web-config";
import axios from "axios";
import { toast } from "sonner";

// Action to get git orgs
export const actionGetGitOrgs = createAsyncThunk(
  "actionGetGitOrgs",
  async ({ userData }) => {
    try {
      const res = await axios.get(`${BASE_URL}orgs?appName=github&userId=${userData?.userId}`);
      const { data, status } = res;
      if (status === 200) {
        return data;
      } else if (status === 401) {
        handleUnauthorized();
        return rejectWithValue("Unauthorized");
      } else if (status === 404) {
        return "";
      } else {
        const customMsg = "No data";
        toast.error(customMsg, 5);
      }
    } catch (error) {
      if (error.status === 401) {
        handleUnauthorized();
      } else {
        toast.error(error?.message, 5);
      }
    }
  }
);

// Action to get git repos
export const actionGetGitOrgRepo = createAsyncThunk(
  "actionGetGitOrgRepo",
  async ({ selectedGitorg, orgId }) => {
    try {
      const res = await axios.get(`${BASE_URL}${selectedGitorg?.id}/repos?appName=github&organizationId=${orgId}&gitOrgName=${selectedGitorg?.login}`);
      const { data, status } = res;
      if (status === 200) {
        return data;
      } else if (status === 401) {
        handleUnauthorized();
        return rejectWithValue("Unauthorized");
      } else if (status === 404) {
        return "";
      } else {
        const customMsg = "No data";
        toast.error(customMsg, 5);
      }
    } catch (error) {
      if (error.status === 401) {
        handleUnauthorized();
      } else {
        toast.error(error?.message, 5);
      }
    }
  }
);

// Action to get create project with selected repos
export const actionGetGitFileUpload = createAsyncThunk(
  "actionGetGitFileUpload",
  async ({ selectedGitorgRepo, orgId ,toProjectPage}) => {
    try {
      const res = await axios.get(`${BASE_URL}file-upload/${selectedGitorgRepo?.name}?appName=github&organizationId=${orgId}`);
      const { data, status } = res;
      if (status === 200) {
        toProjectPage();
        return data;
      } else if (status === 401) {
        handleUnauthorized();
        return rejectWithValue("Unauthorized");
      } else if (status === 404) {
        return "";
      } else {
        const customMsg = "No data";
        toast.error(customMsg, 5);
      }
    } catch (error) {
      if (error.status === 401) {
        handleUnauthorized();
      } else {
        toast.error(error?.message, 5);
      }
    }
  }
);

// Action to get create project with selected repos
export const actionPutGitUpdateFile = createAsyncThunk(
  "actionPutGitUpdateFile",
  async ({ projectId,orgId ,toProjectPage}) => {
    try {
      const res = await axios.put(`${BASE_URL}update-file/${projectId}?appName=github&organizationId=${orgId}`);
      const { data, status } = res;
      if (status === 200) {
        toProjectPage();
        return data;
      } else if (status === 401) {
        handleUnauthorized();
        return rejectWithValue("Unauthorized");
      } else if (status === 404) {
        return "";
      } else {
        const customMsg = "No data";
        toast.error(customMsg, 5);
      }
    } catch (error) {
      if (error.status === 401) {
        handleUnauthorized();
      } else {
        toast.error(error?.message, 5);
      }
    }
  }
);

export const actionSCMauth = createAsyncThunk(
    "actionSCMauth",
    async () => {
      try {
        const res = await axios.get(`${BASE_URL}scm/redirect/github`);
        const { data, status } = res;
        if (status === 200) {
          // window.open(data, "_blank");
          window.location.href = data;
          return data;
        } else {
          toast.error("No URL returned from the server.", 5);
        }
      } catch (error) {
        toast.error(error?.message, 5);
      }
    }
  );
  
  export const actionSCMcallback = createAsyncThunk(
    "actionSCMcallback",
    async (datas, { dispatch }) => {
      try {
        const res = await axios.get(`${BASE_URL}scm/callback/github?code=${datas?.code}`);
        const { data, status } = res;
        if (status === 200) {
          dispatch(actionGetGitOrgs({'userData':datas?.userData}))
          return data;
        } else {
          toast.error("No response from the server.", 5);
        }
      } catch (error) {
        toast.error(error?.message, 5);
      }
    }
  );