import React, { createContext, useState, useEffect, useContext } from "react";

// Create the context
const CollapsedContext = createContext();

// Create the provider component
export const CollapsedProvider = ({ children }) => {
  const [collapsed, setCollapsed] = useState(() => {
    const savedState = localStorage.getItem("collapsed");
    return savedState ? JSON.parse(savedState) : false;
  });

  // Update localStorage whenever collapsed state changes
  useEffect(() => {
    localStorage.setItem("collapsed", JSON.stringify(collapsed));
  }, [collapsed]);

  return (
    <CollapsedContext.Provider value={{ collapsed, setCollapsed }}>
      {children}
    </CollapsedContext.Provider>
  );
};

export const useCollapsed = () => {
    const context = useContext(CollapsedContext);
    if (!context) {
      throw new Error("useCollapsed must be used within a CollapsedProvider");
    }
    return context;
};
